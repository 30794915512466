"use client";

import styled, { css } from "styled-components";
import { colors, includeSpacing, rem } from "@boxt/design-system";

const getBorderStyle = (borderWidth: number) => `${rem(borderWidth)} solid ${colors.platinum.dark}`;

export const Container = styled.div<{ $addContainerSpacing: boolean }>`
  overflow: hidden;
  word-break: break-word;
  ${({ $addContainerSpacing }) =>
    $addContainerSpacing &&
    css`
      ${includeSpacing({ mb: { md: 5, sm: 4 } })};
    `}
  color: ${colors.coal.lighter};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${includeSpacing({ mb: { md: 3, sm: 2 }, mt: { md: 5, sm: 4 } })}

    span {
      color: inherit !important;
    }

    b {
      font-weight: inherit;
    }
  }

  p {
    ${includeSpacing({ mb: 2 })}
  }

  ul,
  ol {
    list-style: none;
    margin-bottom: 2rem;
  }

  ul li {
    &::before {
      content: "•";
      vertical-align: text-top;
    }
  }

  ol {
    counter-reset: li;

    li {
      counter-increment: li;

      &::before {
        content: counter(li) ".\\00a0 ";
        vertical-align: baseline;
      }
    }
  }

  & > {
    :not(h1, h2, h3, h4, h5, h6) {
      span {
        font-weight: 100 !important;
      }
    }
  }

  strong span {
    font-weight: 600 !important;
  }

  b {
    font-weight: 600;
  }

  blockquote {
    padding-left: ${rem(10)};
    margin: ${rem(22)};
    border-left: ${rem(4)} solid ${colors.platinum.dark};

    p:last-child {
      padding-bottom: 0;
    }
  }

  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-spacing: 0;
    border: ${rem(2)} solid ${colors.platinum.dark};
    background-color: ${colors.white};
    table-layout: fixed;

    th,
    td {
      padding: 0.5rem;
      line-height: 1rem;
      vertical-align: top;
      border: ${getBorderStyle(1)};

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }

    > thead {
      th,
      td {
        vertical-align: bottom;
        border-bottom: ${getBorderStyle(2)};
      }
    }

    > tfoot td {
      border-top: ${getBorderStyle(2)};
      border-bottom: 0;
    }

    > thead:first-child {
      > tr:first-child {
        > th,
        > td {
          border-top: 0;
        }
      }
    }

    > tbody + tbody {
      border-top: ${getBorderStyle(2)};
    }

    & table,
    & .table {
      background-color: ${colors.white};
    }
  }

  th {
    text-align: left;
  }
`;
