"use client";

import styled from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const Label = styled.label.withConfig({
  shouldForwardProp: (prop) => !["isSelected"].includes(prop),
})<{ isSelected: boolean }>`
  border: ${({ isSelected }) => (isSelected ? "1px solid transparent" : `1px solid ${colors.platinum.normal}`)};
  background-color: ${({ isSelected }) => (isSelected ? colors.jade.normal : colors.white)};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(92)};
  height: ${rem(84)};

  &:hover {
    border-color: ${colors.jade.normal};
    background-color: ${colors.jade.normal};
    color: ${colors.white};
  }
`;

export const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
