import styled from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const DiscountsContainer = styled.div<{
  $extrasversion: boolean;
  $checkoutVersion: boolean;
  $isBottomSheet: boolean;
}>`
  width: 100%;
  height: auto;
  padding: ${rem(16)};
  border-radius: 20px;
  background-color: ${({ $checkoutVersion }) => ($checkoutVersion ? colors.white : colors.slate.lightest)};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(8)};

  ${includeSpacing({ mt: 1 })}

  ${breakpoint("md")} {
    border-radius: 8px;
    background-color: ${({ $extrasversion, $isBottomSheet }) =>
      $extrasversion && !$isBottomSheet ? colors.slate.lightest : colors.white};
  }
`;

export const PromoPillContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: ${rem(80)};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PillLogo = styled.div`
  display: flex;
`;

export const PillText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${rem(16)};
`;

export const DiscountContainer = styled.div`
  white-space: nowrap;
`;

export const DiscountRow = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: ${rem(42)};
`;

export const DottedLine = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px dotted ${colors.platinum.darker};
`;

export const Seperator = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${colors.platinum.darker};
`;
