import styled from "styled-components";
import { rem, Spacing } from "@boxt/design-system";

export const ContentWrapper = styled(Spacing)`
  display: flex;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${rem(8)};
`;
