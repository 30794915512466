"use client";

import styled from "styled-components";
import { breakpoint } from "@boxt/design-system";

export const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;

  ${breakpoint("md")} {
    width: 1.75rem;
    height: 1.75rem;
  }

  ${breakpoint("lg")} {
    width: 2rem;
    height: 2rem;
  }
`;
