"use client";

import styled from "styled-components";
import { colors, includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div<{ $alignLeft?: boolean }>`
  display: flex;
  justify-content: ${({ $alignLeft }) => ($alignLeft ? "flex-start" : "space-between")};
  align-items: center;
  ${includeSpacing({ mb: 1 })}
`;

export const StyledInput = styled.input`
  width: ${rem(100)};
  border: 1px solid ${colors.platinum.normal};
  height: ${rem(40)};
  font-size: ${rem(16)};
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
  background-color: ${colors.platinum.lighter};
  border: none;
  border-bottom: 2px solid ${colors.platinum.light};

  &:disabled {
    background-color: ${colors.white};
    border-bottom: 2px solid ${colors.white};
    color: ${colors.coal.dark};
  }
`;
