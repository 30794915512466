"use client";

import styled from "styled-components";
import { colors } from "@boxt/design-system";

export const StyledBulletItem = styled.span.withConfig({
  shouldForwardProp: (prop) => !["boxtTheme"].includes(prop),
})`
  color: ${colors.slate.normal};
  margin-bottom: 0;
`;
