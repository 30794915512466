import { colors } from "@boxt/design-system";

import Image from "@Components/Image";
import type { ButterFinanceTextTabFields } from "@Components/pages/finance/types";
import { ReactComponent as Amex } from "@Images/icons/am-ex.svg";
import { ReactComponent as ApplePay } from "@Images/icons/apple-pay.svg";
import { ReactComponent as GooglePay } from "@Images/icons/google-pay.svg";
import { ReactComponent as Mastercard } from "@Images/icons/mastercard.svg";
import { ReactComponent as PayPal } from "@Images/icons/paypal.svg";
import { ReactComponent as Visa } from "@Images/icons/visa.svg";

import type { LogoWrapperSpacing } from "../styles";
import { IconWrapper, LogoWrapper } from "../styles";

const defaultIcons = [
  { icon: Visa, key: "visa" },
  { icon: Amex, key: "amex" },
  { icon: Mastercard, key: "mastercard" },
  { icon: GooglePay, key: "googlePay" },
  { icon: PayPal, key: "payPal" },
  { icon: ApplePay, key: "applePay" },
];

export type Props = {
  icons?: ButterFinanceTextTabFields["text_tab_left_column_payment_logos"];
  logoWrapperSpacing?: LogoWrapperSpacing;
};

const Logos = ({ icons, logoWrapperSpacing = { mb: { md: 0, sm: 2 }, ml: { md: 0, sm: 3 } } }: Props) => {
  return (
    <LogoWrapper $spacing={logoWrapperSpacing}>
      {icons?.length
        ? icons.map(({ slug, icon, alt }) => (
            <IconWrapper key={slug}>
              <Image alt={alt} height="24" src={icon} width="36" />
            </IconWrapper>
          ))
        : defaultIcons.map(({ icon: Icon, key }) => (
            <IconWrapper data-testid="logo" key={key}>
              <Icon color={colors.platinum.lighter} />
            </IconWrapper>
          ))}
    </LogoWrapper>
  );
};

export default Logos;
