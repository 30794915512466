import { H4 } from "@boxt/design-system";

import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";

import { Input, Label } from "./styles";

export type Props = {
  name: string;
  onChange: (id: string) => void;
  plan: FinancePlan;
  selectedPlanId: string | undefined;
};

const MonthlyPayment = ({ plan, name, selectedPlanId, onChange }: Props) => {
  const { term: agreementDurationMonths, id } = plan;

  const isMonthlyPaymentSelected = selectedPlanId === id;

  return (
    <>
      <H4
        as={Label}
        boxtTheme={isMonthlyPaymentSelected ? "dark" : "slateDark"}
        htmlFor={`${name}-${id}`}
        isSelected={isMonthlyPaymentSelected}
        weight="regular"
      >
        {agreementDurationMonths}
      </H4>
      <Input
        checked={isMonthlyPaymentSelected}
        data-testid="finance-plan-length"
        id={`${name}-${id}`}
        name={name}
        onChange={() => onChange(id)}
        type="radio"
        value={agreementDurationMonths}
      />
    </>
  );
};

export default MonthlyPayment;
