import styled from "styled-components";
import { breakpoint, colors, Grid, includeSpacing, rem } from "@boxt/design-system";

export const PaymentDetailsContainer = styled(Grid)`
  ${includeSpacing({ mt: 3 })}
  border-radius: 10px;
  background-color: ${colors.slate.lightest};
  width: 100%;
  ${breakpoint("lg")} {
    background-color: ${colors.platinum.lighter};
  }
`;

export const ContentWrapper = styled.div`
  padding-bottom: ${rem(16)};
  ${breakpoint("md")} {
    padding-bottom: ${rem(32)};
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: ${rem(16)};
  ${breakpoint("lg")} {
    display: none;
  }
`;

export const SummaryContainer = styled.div`
  display: block;
  ${breakpoint("lg")} {
    display: none;
  }
`;

export const RightColumnTitleDiv = styled.div`
  text-align: center;
  padding-bottom: ${rem(16)};
`;
