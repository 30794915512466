import type { ChangeEvent, KeyboardEvent, MouseEvent, TouchEvent } from "react";
import { useEffect, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import { scaleLinear } from "d3-scale";
import { breakpoints, Icon, Paragraph } from "@boxt/design-system";

import AdjustableTooltip from "@Components/utils/Tooltip";
import useMedia from "@Hooks/useMedia";

import {
  Container,
  DashedLineLeft,
  DashedLineRight,
  DecrementIcon,
  IconWrapper,
  InactiveLayer,
  IncrementalButtonsWrapper,
  IncrementButton,
  IncrementIcon,
  Input,
  InputContainer,
  LeftPercentage,
  LockWrapper,
  MinValue,
  PercentageWrapper,
  RangeIndicator,
  RightPercentage,
  Tooltip,
  ToolTipContainer,
} from "./styles";
import { useSliderMountAnimation } from "./useSliderMountAnimation";

export type RangeSliderTheme = "jade" | "coral";

export type Props = {
  buttonStep?: number;
  decrementButtonAriaLabel?: string;
  hasMaxAndMinPercent?: boolean;
  hasIncrementButtons?: boolean;
  hasToolTip?: boolean;
  incrementButtonAriaLabel?: string;
  max: number;
  min: number;
  onAfterChange: (value: number) => void;
  onChange: (value: number) => void;
  sliderAriaLabel?: string;
  step: number;
  theme?: RangeSliderTheme;
  tooltipValue?: string;
  value: number;
  animateOnMount?: boolean;
  animationDelay?: number;
  minDepositInPercentage?: number;
  includeScrollDelay?: boolean;
  depositGuideMessage?: string;
};

const RangeSlider = ({
  decrementButtonAriaLabel,
  hasIncrementButtons = false,
  hasMaxAndMinPercent = false,
  hasToolTip = false,
  incrementButtonAriaLabel,
  buttonStep = 5,
  max,
  min,
  onAfterChange,
  onChange,
  sliderAriaLabel,
  step,
  theme = "jade",
  tooltipValue,
  value,
  animateOnMount = false,
  animationDelay = 300,
  includeScrollDelay = false,
  minDepositInPercentage,
  depositGuideMessage,
}: Props) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);

  const [isDragging, setIsDragging] = useState(false);
  const [rangeWidth, setRangeWidth] = useState<number | undefined>();
  const [hasDepositGuildTooltip, setHasDepositGuildTooltip] = useState(false);
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });

  const hasMinDeposit = Number(minDepositInPercentage) > 0;
  const handleHover = (isShown: boolean) => {
    setHasDepositGuildTooltip(isShown);
  };
  useEffect(() => {
    if (width && minDepositInPercentage) {
      setRangeWidth(scaleLinear().domain([0, max]).range([0, width])(minDepositInPercentage));
    }
  }, [minDepositInPercentage, inputRef, max, width]);

  const { animationValue, isAnimating, inputAnimationValue } = useSliderMountAnimation({
    animationDelay,
    componentRef,
    hasAnimationOnMount: animateOnMount,
    hasScrollDelay: includeScrollDelay,
    max,
  });

  const handleOnChange = (value: number) => {
    if (!hasMinDeposit) return onChange(value);
    if (value >= Number(minDepositInPercentage)) {
      onChange(value);
    }
  };
  const handleOnAfterChange = (value: number) => {
    if (!hasMinDeposit) return onAfterChange(value);
    if (value >= Number(minDepositInPercentage)) {
      onAfterChange(value);
    }
  };

  const handleOnIncrement = () => {
    const newValue = value + step;
    const roundedToNearestIncrementStep = Math.ceil(newValue / buttonStep) * buttonStep;
    const updatedValue = roundedToNearestIncrementStep > max ? max : roundedToNearestIncrementStep;
    handleOnChange(updatedValue);
    handleOnAfterChange(updatedValue);
  };

  const handleOnDecrement = () => {
    const newValue = value - step;
    const roundedToNearestIncrementStep = Math.floor(newValue / buttonStep) * buttonStep;
    const updatedValue = roundedToNearestIncrementStep < min ? min : roundedToNearestIncrementStep;

    handleOnChange(updatedValue);
    handleOnAfterChange(updatedValue);
  };

  const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = e;
    if (["ArrowUp", "ArrowRight", "PageUp", "ArrowDown", "ArrowLeft", "PageDown"].includes(key)) {
      const value = Number((target as HTMLInputElement).value);
      handleOnAfterChange(value);
    }
  };

  const getToolTipPosition = () => Number(((value - min) * 100) / (max - min));

  return (
    <>
      <Container $hasIncrementButtons={hasIncrementButtons} ref={componentRef}>
        {hasIncrementButtons && (
          <IncrementalButtonsWrapper mr={3}>
            <IncrementButton aria-label={decrementButtonAriaLabel} onClick={handleOnDecrement}>
              <DecrementIcon />
            </IncrementButton>
          </IncrementalButtonsWrapper>
        )}
        <InputContainer ref={ref}>
          {hasMinDeposit && (
            <>
              <RangeIndicator $left={rangeWidth} />
              <InactiveLayer $width={rangeWidth} />
            </>
          )}

          <Input
            $boxtTheme={theme}
            $isDragging={isDragging}
            $position={animateOnMount && isAnimating ? animationValue : getToolTipPosition()}
            aria-label={sliderAriaLabel}
            max={max}
            min={min}
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
              handleOnChange(Number(value));
            }}
            onKeyUp={handleOnKeyUp}
            onMouseDown={() => setIsDragging(true)}
            onMouseLeave={({ target }: MouseEvent<HTMLInputElement>) => {
              if (!isDragging) return;
              setIsDragging(false);
              handleOnAfterChange(Number((target as HTMLInputElement).value));
            }}
            onMouseUp={({ target }: MouseEvent<HTMLInputElement>) => {
              if (!isDragging) return;
              setIsDragging(false);
              handleOnAfterChange(Number((target as HTMLInputElement).value));
            }}
            onTouchEnd={({ target }: TouchEvent<HTMLInputElement>) => {
              handleOnAfterChange(Number((target as HTMLInputElement).value));
            }}
            step={step}
            type="range"
            value={animateOnMount && isAnimating ? inputAnimationValue : value}
          />
          {hasToolTip && (
            <Tooltip
              $animationValue={animationValue}
              $boxtTheme={theme}
              $isAnimating={isAnimating}
              $position={getToolTipPosition()}
            >
              <Paragraph align="center" boxtTheme="dark" weight="regular">
                {animateOnMount && isAnimating ? `${Math.floor(animationValue / 2)}%` : tooltipValue}
              </Paragraph>
            </Tooltip>
          )}
        </InputContainer>
        {hasIncrementButtons && (
          <IncrementalButtonsWrapper ml={3}>
            <IncrementButton aria-label={incrementButtonAriaLabel} onClick={handleOnIncrement}>
              <IncrementIcon />
            </IncrementButton>
          </IncrementalButtonsWrapper>
        )}
      </Container>
      {hasMaxAndMinPercent && (
        <PercentageWrapper>
          <LeftPercentage data-testid="min-percent">{min}%</LeftPercentage>
          {hasMinDeposit && depositGuideMessage ? (
            <LockWrapper
              $width={rangeWidth}
              data-testid="deposit-range-guid"
              onMouseEnter={() => handleHover(true)}
              onMouseLeave={() => handleHover(false)}
            >
              <DashedLineLeft $width={rangeWidth} />
              <IconWrapper>
                <Icon boxtTheme="slate" name="lock" size="small" />
                {hasDepositGuildTooltip ? (
                  <ToolTipContainer $isToolTipOnTheLeft={isMobile}>
                    <AdjustableTooltip arrowPosition={isMobile ? "left" : "center"} text={depositGuideMessage} />
                  </ToolTipContainer>
                ) : null}
              </IconWrapper>
              <DashedLineRight $width={rangeWidth} />
              <MinValue $isHidden={minDepositInPercentage === value}>{minDepositInPercentage}%</MinValue>
            </LockWrapper>
          ) : null}
          <RightPercentage data-testid="max-percent">{max}%</RightPercentage>
        </PercentageWrapper>
      )}
    </>
  );
};

export default RangeSlider;
