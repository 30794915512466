"use client";

import styled from "styled-components";
import { breakpoint } from "@boxt/design-system";

export const RightColumnContainer = styled.div`
  display: none;
  ${breakpoint("lg")} {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
`;
