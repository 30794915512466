import styled from "styled-components";

export const LoadingWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const CalculatingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
`;
