import { useCallback, useEffect, useState } from "react";

type Props = {
  animationDelay: number;
  max: number;
  hasAnimationOnMount: boolean;
  componentRef: { current: HTMLDivElement | null };
  hasScrollDelay: boolean;
};

const sliderMountAnimationHelper = (max: number) => {
  const targetPercentage = (70 / 100) * max; // 70% of max
  const arrLength = max - targetPercentage;
  const countDelay = 750 / (arrLength * 2); // 750ms divided by the number of steps

  const steps = Array.from({ length: arrLength * 2 }, (_, i) =>
    i <= arrLength ? 100 - i : 100 - arrLength + (i - arrLength),
  );

  return { countDelay, steps };
};

const useSliderMountAnimation = ({ hasAnimationOnMount, animationDelay, componentRef, max, hasScrollDelay }: Props) => {
  const [animationValue, setAnimationValue] = useState(100);
  const [isInViewport, setIsInViewport] = useState(!hasScrollDelay);
  const [isAnimating, setIsAnimating] = useState(hasAnimationOnMount);

  const getIsInViewport = useCallback(() => {
    const element = componentRef.current;
    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      return elementTop < windowHeight;
    }
    return false;
  }, [componentRef]);

  // This runs on first load. If the slider is in view, it will animate without having to scroll.
  useEffect(() => {
    if (getIsInViewport()) {
      setIsInViewport(true);
    }
  }, [getIsInViewport]);

  useEffect(() => {
    const handleScroll = () => {
      if (getIsInViewport()) {
        setIsInViewport(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };
    if (hasAnimationOnMount && hasScrollDelay) {
      window.addEventListener("scroll", handleScroll);
    }
  });

  useEffect(() => {
    if (isInViewport && hasAnimationOnMount && isAnimating) {
      const timeoutId = setTimeout(() => {
        const { steps, countDelay } = sliderMountAnimationHelper(max);
        steps.forEach((value, i) => {
          setTimeout(() => {
            setAnimationValue(value);
            if (i === steps.length - 1) {
              setIsAnimating(false);
            }
          }, i * countDelay);
        });
      }, animationDelay);

      return () => clearTimeout(timeoutId);
    }
  });
  return {
    animationValue,
    inputAnimationValue: animationValue / (100 / max),
    isAnimating,
  };
};

export { useSliderMountAnimation, sliderMountAnimationHelper };
