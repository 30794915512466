import type { ReactElement } from "react";
import { BulletPoints } from "@boxt/design-system";

import ButterHTMLParsingContent from "@Components/pages/common/ButterHTMLParsingContent";

import ParsedLink from "../ParsedLink";

import { StyledBulletItem } from "./styles";

export type BulletItem = {
  icon: ReactElement;
  item: string;
  key: string;
};

type Props = {
  idAttribute: string;
  items: BulletItem[];
  theme?: "slate" | "coral" | "jade";
};

const componentMap = {
  a: {
    renderedAs: ParsedLink,
  },
  p: {
    styledAs: StyledBulletItem,
  },
};

const getParsedBulletItems = (items: BulletItem[], idAttribute: string) =>
  items.map(({ key, item, icon }) => ({
    icon,
    item: (
      <ButterHTMLParsingContent
        addContainerSpacing={false}
        componentMap={componentMap}
        fields={{ content: item }}
        idAttribute={idAttribute}
      />
    ),
    key,
  }));

const ParsedBulletPoints = ({ idAttribute, items, theme, ...restProps }: Props) => {
  return <BulletPoints boxtTheme={theme} items={getParsedBulletItems(items, idAttribute)} {...restProps} />;
};

export default ParsedBulletPoints;
