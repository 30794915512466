"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, includeShadows, includeSpacing, rem, Spacing } from "@boxt/design-system";

import { ReactComponent as MinusSVG } from "@Images/icons/minus.svg";
import { ReactComponent as PlusSVG } from "@Images/icons/plus.svg";

import type { RangeSliderTheme } from "./RangeSlider";

const dashedLineStyles = css<{ $width?: number }>`
  border: none;
  border-top: 1px dotted #c1c0c0;
  color: ${colors.white};
  height: ${rem(1)};
  left: ${rem(5)};
  width: ${({ $width }) => $width && `${rem($width - 36)}`};
`;

export const Container = styled.div<{ $hasIncrementButtons: boolean }>`
  position: relative;

  ${({ $hasIncrementButtons }) =>
    $hasIncrementButtons &&
    css`
      display: flex;
      align-items: center;
    `};
`;

const runnableTrackStyle = css`
  appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
  box-sizing: border-box;
  cursor: pointer;
`;

const handleStyle = ($isDragging: boolean, $boxtTheme: RangeSliderTheme) => css`
  appearance: none;
  width: ${rem(26)};
  height: ${rem(26)};
  margin-top: -10px;
  cursor: ${$isDragging ? "grabbing" : "grab"};
  border-radius: 50%;
  border: solid 4px ${colors.white};
  background-color: ${colors[$boxtTheme].normal};
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0px #0000004d;
`;

export const Tooltip = styled.output<{
  $position: number;
  $boxtTheme: RangeSliderTheme;
  $animationValue?: number;
  $isAnimating?: boolean;
}>`
  position: absolute;
  width: ${rem(40)};
  height: ${rem(25)};
  background-color: ${({ $boxtTheme }) => colors[$boxtTheme].normal};
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  z-index: 1;
  left: ${({ $position, $animationValue, $isAnimating }) =>
    $isAnimating && $animationValue
      ? `calc(${$animationValue}% + (${13 - $animationValue * 0.26}px))`
      : `calc(${$position}% + (${13 - $position * 0.26}px))`}; // Magic numbers based on size of the native UI thumb
  top: ${rem(34)};
  ${includeShadows(3)}
  transform: translateX(-50%);
`;

export const Input = styled.input<{ $position: number; $isDragging: boolean; $boxtTheme: RangeSliderTheme }>`
  appearance: none;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 0;
  background: ${colors.platinum.light};
  border-radius: 5px;
  background-image: ${({ $boxtTheme }) =>
    `linear-gradient(${colors[$boxtTheme].normal}, ${colors[$boxtTheme].normal})`};
  background-size: ${({ $position }) => `${$position}% 100%`};
  background-repeat: no-repeat;
  height: ${rem(4)};
  cursor: pointer;
  outline: none;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${rem(4)};
    border-radius: 6px;
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    ${({ $isDragging, $boxtTheme }) => handleStyle($isDragging, $boxtTheme)}
  }

  &::-webkit-slider-runnable-track {
    ${runnableTrackStyle};
  }

  &::-moz-range-track {
    ${runnableTrackStyle};
  }

  &::-moz-range-thumb {
    ${({ $isDragging, $boxtTheme }) => handleStyle($isDragging, $boxtTheme)}
  }

  &::-ms-track {
    ${runnableTrackStyle};
  }

  &::-ms-thumb {
    ${({ $isDragging, $boxtTheme }) => handleStyle($isDragging, $boxtTheme)}
  }
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

export const IncrementButton = styled.button`
  appearance: none;
  border: 1px solid ${colors.platinum.normal};
  background: ${colors.white};
  border-radius: 6px;
  width: ${rem(48)};
  height: ${rem(48)};
  color: ${colors.coal.normal};
  cursor: pointer;
  flex-shrink: 0;
  font-size: ${rem(26)};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IncrementIcon = styled(PlusSVG)`
  width: ${rem(30)};
  height: ${rem(30)};
`;

export const DecrementIcon = styled(MinusSVG)`
  width: ${rem(30)};
  height: ${rem(30)};
`;

export const IncrementalButtonsWrapper = styled(Spacing)`
  display: none;

  ${breakpoint("md")} {
    display: block;
  }
`;

export const PercentageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: ${rem(22)};
`;

export const LeftPercentage = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const MinValue = styled.div<{ $isHidden: boolean }>`
  height: 100%;
  display: flex;
  animation: ${({ $isHidden }) => ($isHidden ? "fade-out 200ms forwards" : "fade-in 200ms forwards")};
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const RightPercentage = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

export const RangeIndicator = styled.div<{ $left?: number }>`
  position: absolute;
  height: ${rem(22)};
  width: ${rem(3)};
  bottom: -8px;
  border-radius: 5px;
  background: ${colors.slate.normal};
  left: ${({ $left }) => $left && `${rem($left - 16)}`};
`;

export const InactiveLayer = styled.div<{ $width?: number }>`
  position: absolute;
  height: ${rem(5)};
  background-color: ${colors.white};
  opacity: 0.5;
  filter: blur(0);
  bottom: ${rem(1)};
  left: 0;
  width: ${({ $width }) => $width && `${rem($width - 16)}`};
`;

export const DashedLineLeft = styled.div<{ $width?: number }>`
  ${dashedLineStyles}
  ${includeSpacing({ ml: 1 })}
`;

export const DashedLineRight = styled.div<{ $width?: number }>`
  ${dashedLineStyles}
  ${includeSpacing({ mr: 1 })}
`;

export const LockWrapper = styled.div<{ $width?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ $width }) => $width && `${rem($width - 16)}`};
`;

export const ToolTipContainer = styled.div<{ $isToolTipOnTheLeft: boolean }>`
  z-index: 1;
  position: absolute;
  width: ${rem(284)};
  top: ${rem(16)};
  left: -${({ $isToolTipOnTheLeft }) => ($isToolTipOnTheLeft ? `${rem(34)}` : `${rem(132)}`)};
`;

export const IconWrapper = styled.div`
  position: relative;
`;
