import type { ReactNode, Ref } from "react";
import Lottie from "react-lottie-player";
import type { AnimationEventCallback, AnimationItem } from "lottie-web";
import { Spacing } from "@boxt/design-system";

import Image from "@Components/Image";

import { Container, SpacingSpan, StarContainer } from "./styles";
import type { Size } from "./types";

export type Props = {
  children: ReactNode;
  size?: Size;
  animationData?: object;
  playerRef?: Ref<AnimationItem | undefined>;
  onComplete?: AnimationEventCallback;
  onLoad?: AnimationEventCallback;
};

const BogofOffer = ({ children, size, animationData, onComplete, playerRef, onLoad }: Props) => (
  <Container>
    <StarContainer size={size}>
      {animationData ? (
        <Lottie
          animationData={animationData}
          loop={false}
          onComplete={onComplete}
          onLoad={onLoad}
          play={false}
          ref={playerRef}
        />
      ) : (
        <Image alt="" height={24} objectFit="cover" src="/_next-public/images/bogof-star.png" width={24} />
      )}
    </StarContainer>
    <Spacing {...(size === "large" && { mv: 1 })} as={SpacingSpan} mr={1}>
      {children}
    </Spacing>
  </Container>
);

export default BogofOffer;
