import { poundsToPence } from "@Helpers/currency";
import { parseDigits, parseFinanceDigits } from "@Helpers/inputFormatters";

export const DEFAULT_PURCHASE_PRICE = 189500;
export const DEFAULT_MIN_PURCHASE_PRICE = 50000;
const MAX_PURCHASE_PRICE = 9999900;

const isValidPrice = (minPurchasePrice, value: number) => {
  return value >= minPurchasePrice && value <= MAX_PURCHASE_PRICE;
};

const convertCurrencyToPence = (value: string) => {
  const priceWithoutCurrency = parseDigits(value);

  return poundsToPence(Number(priceWithoutCurrency));
};

const convertCurrencyToPenceWithDecimals = (value: string) => {
  const priceWithoutCurrency = parseFinanceDigits(value);

  return poundsToPence(Number(priceWithoutCurrency));
};

export { isValidPrice, convertCurrencyToPence, convertCurrencyToPenceWithDecimals };
