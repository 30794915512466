"use client";

import styled from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const TabContainer = styled.div`
  position: relative;

  ${breakpoint("md")} {
    margin-top: -${rem(260)};
    display: inherit;
  }
  ${breakpoint("lg")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TabHeaders = styled.div`
  display: none;

  ${breakpoint("md")} {
    background-color: ${colors.white};
    margin: 0 auto;
    margin-top: ${rem(80)};
    padding: ${rem(8)};
    border-radius: ${rem(30)};
    box-shadow: 0px 15px 15px rgba(51, 69, 87, 0.15);
    display: flex;
    flex-wrap: wrap;
    gap: ${rem(15)};
    border-radius: ${rem(48)};
    max-width: 100%;
  }
`;

export const TabsDropdownContainer = styled.div`
  z-index: 5;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${rem(16)};

  ${breakpoint("md")} {
    display: none;
  }
`;

export const Tab = styled.button<{ $isSelected: boolean }>`
  min-width: ${rem(80)};
  white-space: nowrap;
  border: none;
  padding: ${rem(16)};
  font-size: ${rem(18)};
  border-radius: ${rem(30)};
  background: ${({ $isSelected }) => ($isSelected ? colors.slate.normal : colors.white)};
  color: ${({ $isSelected }) => ($isSelected ? colors.white : colors.slate.normal)};
  &:hover {
    background: ${({ $isSelected }) => ($isSelected ? colors.slate.normal : colors.white)};
    color: ${({ $isSelected }) => ($isSelected ? colors.white : colors.slate.light)};
  }
`;

export const Container = styled.div`
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: ${rem(1300)};
  background-color: ${colors.white};
  margin-top: ${rem(32)};
  min-height: ${rem(400)};
  border-radius: ${rem(12)};
  padding: ${rem(16)};
  position: relative;
  display: flex;
  ${breakpoint("lg")} {
    min-height: ${rem(604)};
  }
  ${breakpoint("md")} {
    box-shadow: 0px 25px 25px rgba(51, 69, 87, 0.08);
  }
`;

export const LeftColumnDiv = styled.div`
  border-radius: ${rem(10)};
  padding: ${rem(16)};
  ${includeSpacing({ mb: { lg: 0, md: 1, sm: 1 } })};

  ${breakpoint("lg")} {
    padding: ${rem(16)} ${rem(32)} ${rem(16)} ${rem(16)};
  }
`;

export const RightColumnDiv = styled.div`
  background-color: ${colors.platinum.lighter};
  border-radius: 10px;
  padding: ${rem(16)} ${rem(32)};
  position: relative;

  ${breakpoint("lg")} {
    padding-bottom: 0;
  }
`;
