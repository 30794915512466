import type { Dispatch, FocusEvent, SetStateAction } from "react";
import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";

import { penceToPounds } from "@Helpers/currency";
import LabelledInput from "@Components/FinanceCalculator/components/LabelledInput";
import { convertCurrencyToPenceWithDecimals } from "@Components/FinanceCalculator/helpers";
import RangeSlider from "@Components/RangeSlider";
import type { RangeSliderTheme } from "@Components/RangeSlider/RangeSlider";

export type Props = {
  depositValueInPence: number;
  setDepositAmount: Dispatch<SetStateAction<number | undefined>>;
  purchasePrice: number | undefined;
  sliderTheme?: RangeSliderTheme;
  stepNumber?: number;
  animationDelay?: number;
  minDepositInPercentage?: number;
  includeScrollDelay?: boolean;
  hasDepositCards?: boolean;
  animateOnMount?: boolean;
  depositGuideMessage?: string;
};

export const convertToPercentage = (total: number, deposit?: number) => (Number(deposit) * 100) / total;

const DepositSlider = ({
  purchasePrice,
  setDepositAmount,
  depositValueInPence,
  depositGuideMessage,
  sliderTheme = "jade",
  stepNumber = 1,
  animationDelay = 300,
  includeScrollDelay = false,
  animateOnMount = true,
  minDepositInPercentage,
  hasDepositCards,
}: Props) => {
  const { t } = useTranslation("common");
  const [sliderValueInPercentage, setSliderValueInPercentage] = useState(
    convertToPercentage(Number(purchasePrice), depositValueInPence),
  );
  const hasMinDeposit = Number(minDepositInPercentage) > 0;
  const maxDepositAmount = Number(purchasePrice) / 2;

  useEffect(() => {
    if (hasMinDeposit && sliderValueInPercentage < Number(minDepositInPercentage)) {
      setSliderValueInPercentage(Number(minDepositInPercentage));
      setDepositAmount(Math.round(Number(purchasePrice) * (Number(minDepositInPercentage) / 100)));
    }
  }, [hasMinDeposit, minDepositInPercentage, purchasePrice, setDepositAmount, sliderValueInPercentage]);

  const validateDeposit = (value: number): { isValid: boolean; suggestedDeposit?: number } => {
    let isValid = value <= maxDepositAmount;
    let suggestedDeposit: number | undefined = maxDepositAmount;
    if (hasMinDeposit) {
      const minDepositInPence = Number(purchasePrice) * (Number(minDepositInPercentage) / 100);
      isValid = value >= minDepositInPence && value <= maxDepositAmount;
      if (value < minDepositInPence) {
        suggestedDeposit = minDepositInPercentage;
      }
    }
    return { isValid, suggestedDeposit };
  };

  const handleInputChange = (value: string) => {
    const valueInPence = convertCurrencyToPenceWithDecimals(value);
    const { isValid } = validateDeposit(valueInPence);
    if (isValid) {
      setDepositAmount(valueInPence);
      setSliderValueInPercentage(convertToPercentage(purchasePrice as number, valueInPence));
    }
  };

  const handleDepositBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const valueInPence = convertCurrencyToPenceWithDecimals(value);
    const { isValid, suggestedDeposit } = validateDeposit(valueInPence);

    if (!isValid) {
      setDepositAmount(suggestedDeposit);
      setSliderValueInPercentage(convertToPercentage(purchasePrice as number, suggestedDeposit));
    }
  };

  const onDisplayablePercentChange = (value: number) => {
    setSliderValueInPercentage(value);
  };

  const onAfterChange = (value: number) => {
    setDepositAmount(Math.floor(Number(purchasePrice) * (value / 100)));
  };

  return (
    <>
      <LabelledInput
        hasDepositCards={hasDepositCards}
        labelText={hasDepositCards ? t("calculator.custom-amount") : t("calculator.deposit-amount", { stepNumber })}
        name="deposit-amount"
        onBlur={handleDepositBlur}
        onChange={handleInputChange}
        value={String(penceToPounds(Math.floor(depositValueInPence)))}
      />
      <RangeSlider
        animateOnMount={animateOnMount ? !hasDepositCards : animateOnMount}
        animationDelay={animationDelay}
        decrementButtonAriaLabel={t("calculator.slider-decrement-aria-label")}
        depositGuideMessage={depositGuideMessage}
        hasMaxAndMinPercent
        hasToolTip
        includeScrollDelay={includeScrollDelay}
        incrementButtonAriaLabel={t("calculator.slider-increment-aria-label")}
        max={50}
        min={0}
        minDepositInPercentage={minDepositInPercentage}
        onAfterChange={onAfterChange}
        onChange={onDisplayablePercentChange}
        sliderAriaLabel={t("calculator.slider-aria-label")}
        step={1}
        theme={sliderTheme}
        tooltipValue={`${Math.floor(sliderValueInPercentage)}%`}
        value={sliderValueInPercentage}
      />
    </>
  );
};

export default DepositSlider;
