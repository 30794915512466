import type { FocusEvent } from "react";
import { noop } from "lodash-es";
import { Rifm } from "rifm";
import { H5, Spacing } from "@boxt/design-system";

import { currencyFormatWithPence } from "@Helpers/inputFormatters";

import { Container, StyledInput } from "./styles";

export type Props = {
  labelText: string;
  onChange?: (value: string) => void;
  value: string;
  format?: (value: string) => string;
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hasDepositCards?: boolean;
};

const LabelledInput = ({
  labelText,
  value,
  name,
  onChange = noop,
  onBlur,
  disabled = false,
  hasDepositCards,
  format = currencyFormatWithPence,
}: Props) => {
  return (
    <Container $alignLeft={hasDepositCards}>
      <Spacing mr={hasDepositCards ? 1 : 0}>
        <H5 bottom={3}>{labelText}</H5>
      </Spacing>

      <Rifm accept={/[\d.$]/g} format={format} onChange={onChange} value={value}>
        {({ value, onChange }) => (
          <StyledInput
            data-testid={name}
            disabled={disabled}
            id={name}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            type="text"
            value={value}
          />
        )}
      </Rifm>
    </Container>
  );
};

export default LabelledInput;
