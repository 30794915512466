"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, H5, Paragraph, rem } from "@boxt/design-system";

export const BulletImageContainer = styled.div<{ $hasImage: boolean }>`
  a {
    color: ${colors.jade.normal};
  }

  ${({ $hasImage }) =>
    $hasImage &&
    css`
      display: grid;
      gap: ${rem(32)};
      grid-template-columns: 1fr;

      ${breakpoint("md")} {
        grid-template-columns: 1.75fr 1fr;
      }
    `}
`;

export const ImageContainer = styled.div`
  display: none;
  justify-content: flex-end;

  ${breakpoint("max")} {
    display: flex;
  }
`;

export const SubText = styled(Paragraph)`
  color: ${colors.slate.dark};
`;

export const SubTitleOne = styled(H5)`
  color: ${colors.slate.dark};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
