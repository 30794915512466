import type { ReactNode } from "react";

export type Props = {
  isSelected: boolean;
  children: ReactNode;
};

const TabContent = ({ isSelected, children }: Props) => {
  if (!isSelected) return null;

  return <>{children}</>;
};

export default TabContent;
