import { useEffect, useRef } from "react";

const keepPreviousData = (useSWRNext) => {
  return (key, fetcher, config) => {
    // Use a ref to store previous returned data.
    const previousDataRef = useRef();

    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        previousDataRef.current = swr.data;
      }
    }, [swr.data]);

    // Fallback to previous data if the current data is undefined.
    const currentOrPreviousData = swr.data === undefined ? previousDataRef.current : swr.data;

    return Object.assign({}, swr, {
      data: currentOrPreviousData,
    });
  };
};

export default keepPreviousData;
