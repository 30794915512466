"use client";

import styled from "styled-components";
import { colors, rem, Spacing } from "@boxt/design-system";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(8)};
`;

export const InterestGroupWrapper = styled.div`
  text-align: center;
`;

export const LabelContainer = styled(Spacing)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const Line = styled.div`
  border-bottom: 1px dashed ${colors.platinum.normal};
  flex-grow: 1;
  max-height: ${rem(12)};
`;

export const LeftLine = styled(Line)`
  border-left: 1px dashed ${colors.platinum.normal};
`;

export const RightLine = styled(Line)`
  border-right: 1px dashed ${colors.platinum.normal};
`;

export const StyledLabel = styled.label<{ $isZero: boolean }>`
  border-radius: 4px;
  background-color: ${({ $isZero }) => ($isZero ? colors.sky.normal : colors.white)};
`;
