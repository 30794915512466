import styled from "styled-components";
import { rem } from "@boxt/design-system";

import type { Size } from "./types";

export const Container = styled.span`
  display: flex;
  align-items: center;
`;

export const StarContainer = styled.span<{ size?: Size }>`
  display: inline-block;
  height: ${({ size }) => (size === "small" ? rem(24) : rem(38))};
  width: ${({ size }) => (size === "small" ? rem(24) : rem(38))};
`;

export const SpacingSpan = styled.span`
  display: inline-block;
`;
