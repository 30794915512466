import { H4, Paragraph, ToolTip } from "@boxt/design-system";

import { StyledHr } from "@Components/layout";
import { ReactComponent as InformationIcon } from "@Images/icons/information-icon.svg";

import { ContentWrapper, LabelWrapper } from "./styles";

export type Props = {
  label: string;
  value: string | undefined;
  tooltipText?: string;
  testId?: string;
};

const PaymentDetail = ({ label, value, tooltipText, testId }: Props) => {
  return (
    <>
      <ContentWrapper mv={3}>
        <LabelWrapper>
          <Paragraph boxtTheme="slate">{`${label}:`}</Paragraph>
          {tooltipText && (
            <ToolTip toolTipText={tooltipText}>
              <InformationIcon data-testid="tooltip-icon" />
            </ToolTip>
          )}
        </LabelWrapper>
        <H4 boxtTheme="coalDark" data-testid={testId} weight="regular">
          {value}
        </H4>
      </ContentWrapper>
      <StyledHr />
    </>
  );
};

export default PaymentDetail;
